:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
body{
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.6rem;
    line-height: 1.7;
    background-color: #2C5091;
}
#nav-sub{
    background-color: #192c4e;
    height: 48px;
    line-height: 48px;
    font-size: 15px;
}
#desktopMainSubMenu{
    display: none;
}
.desktopMainSubMenu{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
    background-color: #192C4E;
}
.desktopMainSubMenu > li{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    position: relative;
    width: auto;
}
.desktopMainSubMenu > li > a{
    padding: 1.5rem;
}
.desktopMainSubMenu > li.parent-here > a{
    color: #bc1e21;
    background-color: #FFFFFF;
}
.desktopMainSubMenu > li.here > a{
    color: #bc1e21;
    background-color: #FFFFFF;
}
.desktopMainSubMenu > li:hover > a{
    color: #bc1e21;
    background-color: #FFFFFF;
}
.desktopMainSubMenu a{
    border: none;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    color: #FFFFFF;
}
.desktopMainSubMenu::after{
    clear: both;
}
.desktopMainSubMenu::before,
.desktopMainSubMenu::after{
    content: "";
    display: table;
}
#mainContent .constrainedWidth,
header.banner .constrainedWidth{
    background-color: #FFFFFF;
    padding: 1.8%;
}
.cta.constrainedWidth{
    background-color: #FFFFFF;
    padding: 3rem 1.8% 3rem 1.8%;
    border-top: 1px solid #3a63aa;
    text-align: center;
    font-size: 2rem;
    overflow: hidden;
    position: relative;
}
.cta.constrainedWidth::before{
    position: absolute;
    display: block;
    content: url('/assets/graphics/svg/star-of-life.svg');
    left: -135px;
    bottom: -50px;
    width: 270px;
    height: 270px;
    opacity: 0.1;
}
.cta.constrainedWidth::after{
    position: absolute;
    display: block;
    content: url('/assets/graphics/svg/star-of-life.svg');
    right: -135px;
    bottom: -50px;
    width: 270px;
    height: 270px;
    opacity: 0.1;
}
.ctaText{
    max-width: 650px;
    margin: auto auto 2rem auto;
    line-height: 1.4;
}
.ctaLearnMore{
    font-weight: bold;
    color: #bc1e21;
}
header.banner .bannerContents{
    border-bottom: 4px solid #bc1e21;
    padding: 2rem 0 2rem 0;
}
header.banner .constrainedWidth{
    padding: 0 1.8% 0 1.8%;
}
footer.contentInfo .footerContents{
    background-color: #192c4e;
    color: #FFFFFF;
    padding: 1.8%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    line-height: 1.5;
}
.footerMenu a{
    color: #FFFFFF;
    font-size: 1.4rem;
    padding: 0.6rem;
}
footer.contentInfo .footerContents h3{
    color: #FFFFFF;
    margin: 0 0 1.5rem 0;
    font-size: 1.8rem;
}
footer.contentInfo .footerContents > div{
    margin: 1rem;
}
footer.contentInfo .footerContents > div:first-child,
footer.contentInfo .footerContents > div:nth-child(2){
    min-width: 330px;
}
footer.contentInfo .footerContents > div:last-child{
    display: none;
}
header.banner .bannerContents > div:first-child{
    max-width: 140px;
}
.desktopMainMenu > li > a{
    padding: 0 0.8rem 0 0.8rem;
}
.desktopMainMenu > li.parent-here > a{
    color: #bc1e21;
}
.desktopMainMenu > li.here > a{
    color: #bc1e21;
}
.desktopMainMenu > li:hover > a{
    color: #bc1e21;
}
.logo,
.logo object{
    display: block;
}
h1,
h2{
    font-weight: 400;
}
.bodyBackgroundImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
}
.desktopMainMenu li.last a{
    padding-right: 0;
}
.ff_composer input[type=submit]{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
.ff_composer input[type=submit]:hover{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
.ff_composer input[type=submit] > *{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.ff_composer input[type=submit]:hover{
    background-color: #395d98;
}
.headerEventLink{
    text-align: right;
}
.headerEventLink a{
    border: 1px solid #bc1e21;
    padding: 0.3rem 1rem 0.3rem 1rem;
    display: inline-block;
    text-decoration: none;
    color: #4b5565;
}
.headerEventLink a:hover{
    color: #bc1e21;
}
.headerEventLink object{
    vertical-align: text-top;
    margin-right: 0.5rem;
    display: inline-block;
}
.cardCopy h2{
    color: #3a63aa;
    margin: 1.8rem 0 1rem 0;
    white-space: nowrap;
}
.cardCopy{
    line-height: 1.3;
}
.cardsWrapper > a{
    text-decoration: none;
}
.profileCards{
    margin: 3rem 0 3rem 0;
}
.cartFieldset legend{
    color: #3a63aa;
    font-size: 2.6rem;
}
header.banner .bannerContents{
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
}
#index .indexArrowCallout{
    float: right;
    width: 270px;
    margin-left: 30px;
    background-color: #FFFFFF;
    margin-bottom: 2rem;
}
#index .indexArrowCallout h2{
    font-size: 1.8rem;
    color: #FFFFFF;
    background-color: #3A63AA;
    padding: 1rem 1.5rem 1rem 1.5rem;
    margin: 0;
    font-weight: bold;
}
#index .indexArrowCallout h2 span{
    float: right;
}
#index .indexArrowCallout a{
    text-decoration: none;
    color: inherit;
}
#index .indexArrowCalloutText{
    font-size: 1.5rem;
    color: #FFFFFF;
    background-color: #192C4E;
    padding: 1.5rem;
}
#index h6.more{
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #333333;
}
#index h6.more a{
    color: #BC1E21;
    text-decoration: none;
}
#index h3.more{
    font-size: 1.6rem;
    font-style: italic;
    color: #3A63AA;
    margin: 2rem 0 2rem 0;
    font-family: serif;
    text-transform: uppercase;
    font-weight: bold;
}
.utilityNav{
    text-align: right;
}
.utilityNav a object{
    display: inline-block;
    margin-right: 1rem;
    vertical-align: baseline;
}
#addLremsPaymentToCartForm,
#addScvemsPaymentToCartForm{
    margin: 3rem 0 3rem 0;
    max-width: 600px;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
@media only screen and (min-width: 500px){

}
@media only screen and (min-width: 650px){

}
@media only screen and (min-width: 760px){

}
@media only screen and (min-width: 800px){
    footer.contentInfo .footerContents > div:first-child,
    footer.contentInfo .footerContents > div:nth-child(2){
        width: 36%;
        padding-right: 8%;
        min-width: 380px;
    }
    .cta.constrainedWidth::before,
    .cta.constrainedWidth::after{
        opacity: 1;
    }
}
@media only screen and (min-width: 1000px){
    #desktopMainSubMenu,
    footer.contentInfo .footerContents > div:last-child{
        display: block;
    }
    header.banner .bannerContents{
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
            -ms-flex-align: end;
                align-items: flex-end;
    }
    .headerEventLink a{
        margin-bottom: 2rem;
    }
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.desktopMainMenu a{
    font-size: 1.6rem;
}
h2.ctaTitle{
    color: #4169ad;
    font-family: 'Source Sans Pro', Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 2.8rem;
    font-weight: normal;
}
/*
assumptions: default font size is 16px and is reset at the :root element to 62.5% which is 10px
locking breakpoints are converted to rems for use in the calculations e.g. 350px = 35rem
font-size: calc([minimum size font in rem] + (([maximum size font in rem without units] - [minimum size font in rem without units]) * (((100vw * .625) - [small breakpoint in rem]) / ([large breakpoint in rem without units] - [small breakpoint in rem without units]) )));
*/
@media screen and (min-width: 400px){
    h2.ctaTitle{
        font-size: calc( 2.8rem + ( ((4.6 - 2.8) * 10) * ((100vw - 400px)/(1000 - 400)) ) );
    }
    .desktopMainMenu a{
        font-size: calc(1.6rem + (((1.8 - 1.6) * 10) * ((100vw - 400px) / (1000 - 400))));
    }
}
@media screen and (min-width: 1000px){
    h2.ctaTitle{
        font-size: 4.6rem;
    }
    .desktopMainMenu a{
        font-size: 1.8rem;
    }
}
