:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.accordion > .accordionContent{
    border-bottom: 1px solid #F4F4F2;
    display: none;
    padding: 1rem;
}
.accordion > .accordionTrigger{
    border-bottom: 2px solid #D4D4D2;
    cursor: pointer;
    padding: 1rem;
}
.accordion > .accordionTrigger > span{
    float: right;
}
.accordion > .accordionTrigger:hover,
.accordion > .accordionTrigger.selected{
}

.breadcrumbs{
    color: undefined;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}
.breadcrumbs a,
.breadcrumbs span{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    text-decoration: none;
    margin-right: 0.8rem;
    position: relative;
}
.breadcrumbs span{
    display: inline-block;
    color: undefined;
}
.breadcrumbs a:last-child{
    color: undefined;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
.buttonsWrapper .button + .button,
.buttonsWrapper .buttonSmall + .buttonSmall,
.buttonsWrapper .button + .buttonSmall,
.buttonsWrapper .buttonSmall + .button{
    margin-top: 1.5rem;
}
.button{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
.button:hover{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
input.button{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
input.button:hover{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
.buttonSmall{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
.buttonSmall:hover{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
input.buttonSmall{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
input.buttonSmall:hover{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #4169ad;
    border: none;
    clear: both;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 1.5rem 4.5rem 1.5rem 4.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    background-image: none;
}
.button > *,
input.button > *,
.buttonSmall > *,
input.buttonSmall > *{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.button i,
input.button i,
.buttonSmall i,
input.buttonSmall i{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
}
.button:hover{
    background-color: #395d98;
}
.buttonSmall:hover{
    background-color: #395d98;
}
input.button:hover{
    background-color: #395d98;
}
input.buttonSmall:hover{
    background-color: #395d98;
}
.buttonSmall,
.buttonSmall:hover,
input.buttonSmall,
input.buttonSmall:hover{
    font-size: 90%;
    padding: 0.8rem 1.3rem 0.8rem 1.3rem;
}
/*
assumptions: default font size is 16px and is reset at the :root element to 62.5% which is 10px
locking breakpoints are converted to rems for use in the calculations e.g. 350px = 35rem
font-size: calc([minimum size font in rem] + (([maximum size font in rem without units] - [minimum size font in rem without units]) * (((100vw * .625) - [small breakpoint in rem]) / ([large breakpoint in rem without units] - [small breakpoint in rem without units]) )));
*/
@media screen and (min-width: 35rem){
    .button{
        font-size: calc(1.6rem + ((1.6 - 1.6) * (((100vw * 0.625) - 35rem) / (90 - 35))));
    }
    .button:hover{
        font-size: calc(1.6rem + ((1.6 - 1.6) * (((100vw * 0.625) - 35rem) / (90 - 35))));
    }
    input.button{
        font-size: calc(1.6rem + ((1.6 - 1.6) * (((100vw * 0.625) - 35rem) / (90 - 35))));
    }
    input.button:hover{
        font-size: calc(1.6rem + ((1.6 - 1.6) * (((100vw * 0.625) - 35rem) / (90 - 35))));
    }
}
@media screen and (min-width: 90rem){
    .button{
        font-size: 1.6rem;
    }
    .button:hover{
        font-size: 1.6rem;
    }
    input.button{
        font-size: 1.6rem;
    }
    input.button:hover{
        font-size: 1.6rem;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
h2.calendarNav{
    text-align: center;
}
h2.calendarNav a:first-child{
    float: left;
}
h2.calendarNav a:last-child{
    float: right;
}
.calendarBG{
    background-color: #000000;
}
.calendarBlank{
    background-color: #9DB7A7;
}
.calendarCell{
    background-color: #FFFFFF;
    color: #666666;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
}
.calendarCell a{
    background-color: transparent;
    color: #000000;
    text-decoration: underline;
}
.calendarCell a:visited{
    background-color: transparent;
    color: #000000;
    text-decoration: underline;
}
.calendarCell a:hover{
    background-color: transparent;
    color: #FFFFFF;
    text-decoration: none;
}
.calendarDayHeading{
    background-color: #663300;
    color: #FFFFFF;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}
.calendarEvent{
    padding: 3px 5px 3px 5px;
}
.calendarEvent > div{
    border-radius: 2px;
    color: #585858;
    font-size: 1.2rem;
    line-height: normal;
    overflow: hidden;
    text-decoration: none;
}
.calendarEvent > div > a{
    -webkit-box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    display: block;
    padding: 7px 5px 7px 5px;
    text-decoration: none;
}
.calendarEvent.eventEndsAfterWeek{
    padding-right: 0;
}
.calendarEvent.eventEndsAfterWeek > div{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.calendarEvent.eventStartsBeforeWeek{
    padding-left: 0;
}
.calendarEvent.eventStartsBeforeWeek > div{
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.calendarEventMarker.calendarEventMarker{
    background-color: #AD006E;
    -webkit-box-shadow: inset 0 0 25px rgba(90, 90, 90, 0.5);
            box-shadow: inset 0 0 25px rgba(90, 90, 90, 0.5);
    color: #FFFFFF;
    font-size: 120%;
    padding: 1.5rem;
}
.calendarEventRow div{
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
}
.calendarFullEvent::before{
    background-color: #FF5500;
    color: #FFFFFF;
    content: "";
    display: block;
    height: 5px;
    width: 100%;
}
.calendarHeader{
    background-color: #000000;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
}
.calendarHeading:first-child{
    border-left: none;
}
.calendarHeadings{
    display: none;
}
.calendarHeadings > div{
    background: #B3B3B1;
    border-left: 1px solid #FFFFFF;
    color: #FFFFFF;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-flex-basis: calc(100% / 7);
        -ms-flex-preferred-size: calc(100% / 7);
            flex-basis: calc(100% / 7);
    font-size: 1.3rem;
    line-height: 35px;
    max-width: calc(100% / 7);
    padding: 0;
    text-align: center;
    text-transform: uppercase;
}
.calendarMonthLinks{
    background-color: transparent;
    color: #FFFFFF;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-decoration: none;
}
.calendarMonthLinks a{
    background-color: transparent;
    color: #FFFFFF;
    text-decoration: none;
}
.calendarMonthLinks a:visited{
    background-color: transparent;
    color: #FFFFFF;
    text-decoration: none;
}
.calendarMonthLinks a:hover{
    background-color: transparent;
    color: #C9C9C7;
    text-decoration: underline;
}
.calendarMonthRow{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    height: 100%;
    min-height: 150px;
    overflow: hidden;
    position: relative;
}
.calendarMonthRowBackground{
    bottom: 0;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0;
        -ms-flex: 1 0 0px;
            flex: 1 0 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
}
.calendarMonthRowBackground > div{
    border-bottom: 1px solid #C9C9C7;
    border-left: 1px solid #C9C9C7;
    -webkit-flex-basis: calc(100% / 7);
        -ms-flex-preferred-size: calc(100% / 7);
            flex-basis: calc(100% / 7);
    width: calc(100% / 7);
}
.calendarMonthRowBackground > div:last-child{
    border-right: 1px solid #C9C9C7;
}
.calendarMonthRowContent{
    padding: 0 0 30px 0;
    position: relative;
}
.calendarMonthRowContentDates{
    padding: 0 0 10px 0;
}
.calendarMonthRowContentDates > div{
    background-color: #DEDEDC;
    border-bottom: 1px solid #D4D4D2;
    border-left: 1px solid #C9C9C7;
    color: #333331;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    min-width: calc(100% / 7);
    padding: 5px 10px 5px 10px;
}
.calendarMonthRowContentDates > div.priorMonthWeekday{
    background-color: #FFFFFF;
    color: #C9C9C7;
}
.calendarMonthRowContentDates > div.priorMonthWeekend{
    background-color: #FFFFFF;
    color: #C9C9C7;
}
.calendarMonthRowContentDates > div.followingMonthWeekday{
    background-color: #FFFFFF;
    color: #C9C9C7;
}
.calendarMonthRowContentDates > div.followingMonthWeekend{
    background-color: #FFFFFF;
    color: #C9C9C7;
}
.stdCalendarDay > div:first-child.priorMonthWeekday{
    background-color: #FFFFFF;
    color: #C9C9C7;
}
.stdCalendarDay > div:first-child.priorMonthWeekend{
    background-color: #FFFFFF;
    color: #C9C9C7;
}
.stdCalendarDay > div:first-child.followingMonthWeekday{
    background-color: #FFFFFF;
    color: #C9C9C7;
}
.stdCalendarDay > div:first-child.followingMonthWeekend{
    background-color: #FFFFFF;
    color: #C9C9C7;
}
.calendarMonthRowContentDates > div:last-child{
    border-right: 1px solid #C9C9C7;
}
.calendarMonthRowContentDates,
.calendarEventRow{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
}
.calendarPlanningEvent::before{
    background-color: #FF0087;
    color: #FFFFFF;
    content: "";
    display: block;
    height: 5px;
    width: 100%;
}
.calendarToday{
    background-color: #C9C9C7;
    color: #000000;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
}
.calendarWrapper{
    position: relative;
    width: 100%;
}
.cat-1{
    background-color: #64ADB3;
}
.cat-10{
    background-color: #A4B392;
}
.cat-11{
    background-color: #B37089;
}
.cat-12{
    background-color: #B38A5B;
}
.cat-13{
    background-color: #AFB37F;
}
.cat-14{
    background-color: #7097B3;
}
.cat-15{
    background-color: #B39D7F;
}
.cat-16{
    background-color: #99B37F;
}
.cat-2{
    background-color: #B37261;
}
.cat-3{
    background-color: #B39B5B;
}
.cat-4{
    background-color: #9298B3;
}
.cat-5{
    background-color: #988DB3;
}
.cat-6{
    background-color: #9BB39B;
}
.cat-7{
    background-color: #74B3AA;
}
.cat-8{
    background-color: #B38191;
}
.cat-9{
    background-color: #5D99B3;
}
.currentMonthWeekend{
    background-color: #FBFBFA;
}
.isToday > div:first-child{
    background-color: #ABABAB;
    color: #FFFFFF;
}
.otherMonthDay{
    background-color: #F8F8F5;
}
.pastCalendarEvent{
    opacity: 0.5;
}
.priorMonthWeekend,
.followingMonthWeekend{
    background-color: #FFFFFF;
}
.stdCalendar{
    border-right: 1px solid #C9C9C7;
}
.stdCalendarDay{
    border-bottom: 1px solid #C9C9C7;
    border-left: 1px solid #C9C9C7;
    padding: 0 0 20px 0;
    width: 100%;
}
.stdCalendarDay > div:first-child{
    background-color: #DEDEDC;
    border-bottom: 1px solid #D4D4D2;
    color: #333331;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    min-width: 14.28571%;
    padding: 5px 10px;
}
.stdCalendarDay.priorMonth,
.stdCalendarDay.followingMonth{
    display: none;
}
.stdCalendarEvent{
    background-color: #56A6E1;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    font-size: 1.2rem;
    line-height: normal;
    margin: 5px;
    overflow: hidden;
    text-decoration: none;
}
.stdCalendarEvent a{
    color: #FFFFFF;
    display: block;
    padding: 7px;
    position: relative;
    text-decoration: none;
}
.stdCalendarEvent.eventFull{
    background-color: #4F8EC4;
}
.stdCalendarEvent.eventFull a::before{
    content: "\f071";
    display: inline-block;
    font-family: "Fontawesome", sans-serif;
    font-weight: normal;
    margin-right: 3px;
}
.stdCalendarEvent.limitedSpace{
    background-color: #6F87C4;
}
@media only screen and (min-width: 700px){
    .stdCalendarDays{
        -js-display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
    }
    .stdCalendarDay{
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 calc(100% / 7);
            -ms-flex: 1 1 calc(100% / 7);
                flex: 1 1 calc(100% / 7);
        width: calc(100% / 7);
    }
    .calendarHeadings{
        -js-display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row nowrap;
            -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
    }
    .stdCalendarDay{
        min-height: 150px;
    }
    .stdCalendarDay.priorMonth,
    .stdCalendarDay.followingMonth{
        display: block;
    }
    .stdCalendarDay > div span{
        display: none;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.cardsWrapper{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 100%;
    position: relative;
    width: 100%;
}
.cardsWrapper > div{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    margin: 0 0 4rem 0;
    width: 100%;
    max-width: calc(48rem - 4rem);
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
}
.cardsWrapper > a{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    margin: 0 0 4rem 0;
    width: 100%;
    max-width: calc(48rem - 4rem);
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
}
.cardsWrapper > div.fillerCard.fillerCard{
    margin-bottom: 0;
    padding: 0;
    visibility: hidden;
}
.cardsWrapper > div.fillerCard *{
    display: none;
}
.cardCopy{
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    position: relative;
}
.cardFlexFooter{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.cardFlexFooter > div{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    width: 50%;
}
.cardFooter{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.cardFooter > div{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.cardFooter,
.cardFlexFooter{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    position: relative;
    text-align: center;
}
.cardHeader{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
}
.cardHeader > div{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.cardImage{
    -webkit-box-flex: 0;
    -webkit-flex: none;
        -ms-flex: none;
            flex: none;
    position: relative;
}
@media only screen and (min-width: 450px){
    .cardsWrapper{
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    .cardsWrapper > div{
        max-width: calc(50% - (4rem - (4rem / 2)));
    }
    .cardsWrapper > a{
        max-width: calc(50% - (4rem - (4rem / 2)));
    }
}
@media only screen and (min-width: 600px){
    .cardsWrapper > div{
        max-width: calc(33.333% - (4rem - (4rem / 3)));
    }
    .cardsWrapper > a{
        max-width: calc(33.333% - (4rem - (4rem / 3)));
    }
}
@media only screen and (min-width: 1000px){
    .cardsWrapper > div{
        max-width: calc(25% - (4rem - (4rem / 4)));
    }
    .cardsWrapper > a{
        max-width: calc(25% - (4rem - (4rem / 4)));
    }
}



:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.cartCancelUpdate{
    right: -170px;
    top: 0;
}
.cartColumn{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 100%;
    padding: 0 1rem 0.5rem 1rem;
    position: relative;
}
.cartDeleteItemCheckbox{
    visibility: hidden;
}
.cartEditRemove{
    right: 0;
    top: 0;
}
.cartEditRemove,
.cartCancelUpdate{
    position: absolute;
    width: 170px;
}
.cartFieldset{
    margin: 0;
}
.cartFieldset .cartFieldGroup{
    max-width: 500px;
}
.cartFieldset legend{
    margin-bottom: 2.5rem;
}
.cartFieldset legend{
    color: #4b5565;
    font-size: 2rem;
}
.cartFieldset legend span{
    display: inline-block;
    font-size: 70%;
    font-weight: normal;
    padding: 0 1rem 0 1rem;
    vertical-align: text-bottom;
}
.cartFieldset legend span a{
    display: inline-block;
    white-space: nowrap;
}
.cartFieldsetWrapper{
    margin: 2rem;
    padding: 1rem;
    background-color: #FCFCFC;
    border: 1px solid #EFEFEF;
    border-radius: 3px;
}
.cartHeader{
    display: none;
    border-bottom: 2px solid #888886;
    clear: both;
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5rem;
}
.cartRow{
    border-bottom: 1px solid #B3B3B1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5rem;
    padding: 0 0 1.5rem 0;
}
.cartRow .cartItemActions{
    overflow: hidden;
    min-height: 30px;
}
.cartHeader .cartColumn.cartItemDetailsHeader{
    text-align: left;
}
.cartHeader .cartColumn.cartItemActionsHeader,
.cartHeader .cartColumn.cartItemQtyHeader,
.cartHeader .cartColumn.cartItemTotalHeader{
    text-align: right;
}
.cartItemActions,
.cartItemQty,
.cartItemTotal{
    text-align: right;
}
.cartItemButton > *{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    padding: 0.2rem;
}
.cartItemButton img{
    vertical-align: baseline;
}
.cartItemButton{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: transparent;
    border: 1px solid #A9A9A7;
    border-radius: 3px;
    clear: both;
    color: #4b5565;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0 0.5rem 0 0.5rem;
    position: relative;
    text-decoration: none;
    background-image: none;
    font-size: 1.2rem;
    margin: 0 0 0 0.5rem;
    vertical-align: middle;
}
.cartItemButton:hover{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: transparent;
    border: 1px solid #A9A9A7;
    border-radius: 3px;
    clear: both;
    color: #4b5565;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    padding: 0 0.5rem 0 0.5rem;
    position: relative;
    text-decoration: none;
    background-image: none;
    font-size: 1.2rem;
    margin: 0 0 0 0.5rem;
    vertical-align: middle;
}
.cartItemButton:hover{
    background-color: #EFEFEF;
}
.cartItemDetails{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.cartItemDetails .cartItemImage{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    max-width: 200px;
    min-width: 100px;
    margin: 1rem auto 2rem auto;
}
.cartItemDetails > div{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 100%;
    position: relative;
}
.cartItemEditable input[type=text][disabled],
.cartItemEditable textarea[disabled]{
    background-color: transparent;
    border-color: transparent;
}
.cartItemEditable input[type=text]{
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border: 1px solid #C9C9C7;
    max-height: 3rem;
}
.cartItemEditable .styledSelect{
    border: 1px solid #C9C9C7;
}
.cartItemEditable .styledSelect::after{
    background-color: #7E7E7C;
    font-size: 1rem;
    padding-top: 0.4rem;
}
.cartItemEditable.disabled .styledSelect::after{
    display: none;
}
.cartItemEditable.disabled .styledSelect,
.cartItemEditable.disabled .styledSelect select{
    background-color: transparent;
    border-color: transparent;
}
.cartItemEditable textarea{
    resize: none;
    overflow-y: auto;
    padding: 0.2rem 1rem 0.2rem 1rem;
    border: 1px solid #C9C9C7;
}
.cartItemName{
    font-weight: 600;
}
.cartItemDescription,
.cartItemOptions{
    padding: 1rem 1rem 1rem 2rem;
}
.cartItemOptionRow{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    position: relative;
    width: 100%;
    padding-bottom: 0.3rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}
.cartItemOptionRow > div{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.cartItemOptionRow > div:first-child{
    width: 20%;
    min-width: 100px;
    max-width: 150px;
    text-align: right;
    padding-right: 1rem;
}
.cartItemQty input[type=text]{
    max-width: 80px;
    text-align: right;
}
.cartMobileRightAlign > span:last-child,
.cartTotalsRow > span:last-child{
    width: 100%;
    display: inline-block;
    padding: 0 0 0 1rem;
    max-width: 100px;
}
.myCartMenuButton img{
    margin: 0 0.7rem 0 0.7rem;
}
.cartPageButtons{
    margin: 2rem;
    text-align: right;
}
.cartRow{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #DEDEDC;
}
.cartTotalsRow{
    text-align: right;
    font-size: 2rem;
}
.cartTotalsRow > span:first-child{
    font-weight: bold;
}
.emptyCartWarning{
    color: #E64F0C;
    font-size: 2.2rem;
    padding: 1.5rem;
}
.mobileCartLabel{
    color: #4b5565;
    display: inline-block;
    font-weight: normal;
    padding-right: 0.5rem;
    text-align: right;
}
.updateCartFormRow:last-of-type .cartRow{
    border-bottom: 2px solid #888886;
}
.btnDel{
    display: inline-block;
    border: none;
    cursor: pointer;
    background-color: transparent;
}
.yourInfoFormAddresses{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
}
.yourInfoFormAddresses > div{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 100%;
}
.reviewYourInformation > div{
    width: 100%;
    margin-bottom: 2rem;
}
.reviewYourInformation > div > h4{
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: 1px solid #BEBEBC;
}
.clearCartButton{
    background-color: #FFFFFF;
    border: 1px solid #838383;
    border-radius: 3px;
    color: #676767;
    display: block;
    float: right;
    font-size: 1.3rem;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem;
    text-decoration: none;
    margin-bottom: 2rem;
}
.clearCartButton:hover{
    background-color: #B12222;
    border: 1px solid #951D1D;
    color: #FFFFFF;
}
.printableOrderDetails{
    display: none;
}
@media only screen and (min-width: 550px){
    .cartItemDetails{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
            -ms-flex-align: start;
                align-items: flex-start;
    }
    .cartItemDetails .cartItemImage{
        margin: 0 2rem 0 0;
    }
}
@media only screen and (min-width: 800px){
    .cartColumn{
        width: auto;
    }
    .cartHeader .cartColumn{
        white-space: nowrap;
    }
    .cartHeader,
    .cartRow{
        -js-display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
            -ms-flex-direction: row;
                flex-direction: row;
    }
    .cartItemActions{
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 180px;
            -ms-flex: 0 0 180px;
                flex: 0 0 180px;
    }
    .cartHeader .cartColumn.cartItemTotalHeader,
    .cartItemTotal{
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 110px;
            -ms-flex: 0 0 110px;
                flex: 0 0 110px;
    }
    .cartHeader .cartColumn.cartItemQtyHeader,
    .cartItemQty{
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 80px;
            -ms-flex: 0 0 80px;
                flex: 0 0 80px;
    }
    .mobileCartLabel{
        display: none;
    }
    .reviewYourInformation{
        -js-display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
            -ms-flex-align: start;
                align-items: flex-start;
    }
    .reviewYourInformation > div{
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        margin: 1rem;
        width: 33.333%;
        max-width: 33.333%;
    }
    .yourInfoFormAddresses{
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
    .yourInfoFormAddresses > div{
        max-width: 50%;
    }
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.arrowedCartBreadcrumbs{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 0 3.0rem 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}
.arrowedCartBreadcrumbs li{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: rgb(163, 184, 220);
    color: #ffffff;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 1px;
    padding: 0;
    position: relative;
    width: 100%;
}
.arrowedCartBreadcrumbs li > *{
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.2;
    padding: 0.5rem 1.0rem 0.5rem 1.0rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;
}
.arrowedCartBreadcrumbs li a{
    color: #ffffff;
}
.arrowedCartBreadcrumbs li.current{
    background-color: rgb(89, 126, 192);
    color: #ffffff;
}
.arrowedCartBreadcrumbs li.current a{
    color: #ffffff;
}
.arrowedCartBreadcrumbs li.seen{
    background-color: #4169ad;
    color: #ffffff;
}
.arrowedCartBreadcrumbs li.seen a{
    color: #ffffff;
}
.arrowedCartBreadcrumbs li:hover.seen{
    background-color: rgb(89, 126, 192);
    color: #ffffff;
}
.arrowedCartBreadcrumbs li:hover.seen a{
    color: #ffffff;
}
@media only screen and (min-width: 400px){
    .arrowedCartBreadcrumbs li{
        max-width: calc(50% - 4px);
    }
}
@media only screen and (min-width: 550px){
    .arrowedCartBreadcrumbs li{
        width: 20%;
    }
    .arrowedCartBreadcrumbs li > *{
        text-align: center;
    }
}
@media only screen and (min-width: 750px){
    .arrowedCartBreadcrumbs li{
        margin: 0;
    }
    .arrowedCartBreadcrumbs li > *{
        font-size: 1.6rem;
    }
    .arrowedCartBreadcrumbs li.current:nth-last-child(n+2) > *::after{
        border-bottom: 40px solid transparent;
        border-left: 19px solid rgb(89, 126, 192);
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li.current:nth-last-child(n+2) > *::before{
        border-bottom: 40px solid transparent;
        border-left: 19px solid #FFFFFF;
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li.seen:hover:nth-last-child(n+2) > a::after{
        border-left: 19px solid rgb(89, 126, 192);
    }
    .arrowedCartBreadcrumbs li.seen:nth-last-child(n+2) > *::after{
        border-bottom: 40px solid transparent;
        border-left: 19px solid #4169ad;
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li.seen:nth-last-child(n+2) > *::before{
        border-bottom: 40px solid transparent;
        border-left: 19px solid #FFFFFF;
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li:nth-last-child(n+2) > *::after{
        content: "";
        display: block;
        height: 0;
        left: calc(100% - 14px);
        margin-top: -40px;
        position: absolute;
        top: 50%;
        width: 0;
        z-index: 2;
    }
    .arrowedCartBreadcrumbs li:nth-last-child(n+2) > *::after{
        border-bottom: 40px solid transparent;
        border-left: 19px solid rgb(163, 184, 220);
        border-top: 40px solid transparent;
    }
    .arrowedCartBreadcrumbs li:nth-last-child(n+2) > *::before{
        content: "";
        display: block;
        height: 0;
        left: calc(100% - 14px);
        margin-left: 2px;
        margin-top: -40px;
        position: absolute;
        top: 50%;
        width: 0;
        z-index: 1;
    }
    .arrowedCartBreadcrumbs li:nth-last-child(n+2) > *::before{
        border-bottom: 40px solid transparent;
        border-left: 19px solid #FFFFFF;
        border-top: 40px solid transparent;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
    /* cart breadcrumbs */
}
.cartBreadcrumbs{
    counter-reset: section;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 0 3.0rem 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}
.cartBreadcrumbs li{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #60b1e4;
    color: #ffffff;
    counter-increment: section;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 1px;
    padding: 0;
    position: relative;
    width: 100%;
}
.cartBreadcrumbs li > *{
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.2;
    padding: 0.5rem 1.0rem 0.5rem 1.0rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;
}
.cartBreadcrumbs li > *::before{
    content: counter(section) ".";
    display: inline-block;
    height: 100%;
    margin-right: 5px;
}
.cartBreadcrumbs li a{
    color: #ffffff;
}
.cartBreadcrumbs li.current{
    background-color: #008cdc;
    color: #ffffff;
}
.cartBreadcrumbs li.current a{
    color: #ffffff;
}
.cartBreadcrumbs li.seen{
    background-color: #0072b3;
    color: #ffffff;
}
.cartBreadcrumbs li.seen a{
    color: #ffffff;
}
.cartBreadcrumbs li.seen:hover{
    background-color: #008cdc;
    color: #ffffff;
}
.cartBreadcrumbs li.seen:hover a{
    color: #ffffff;
}
@media only screen and (min-width: 400px){
    .cartBreadcrumbs li{
        max-width: 50%;
    }
}
@media only screen and (min-width: 550px){
    .cartBreadcrumbs li{
        width: 20%;
    }
    .cartBreadcrumbs li > *{
        text-align: center;
    }
}
@media only screen and (min-width: 750px){
    .cartBreadcrumbs li > *{
        font-size: 1.6rem;
    }
}


:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.confirmBoxButton{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #D4D4D2;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    margin: 0.5rem;
    padding: 1.5rem 3.0rem 1.5rem 3.0rem;
    position: relative;
    text-align: center;
    text-decoration: none;
}
.confirmBoxButton *{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
#confirmBox{
    background-color: #FFFFFF;
    border-radius: 5px;
    left: 50%;
    line-height: 1.4;
    max-width: 300px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
    z-index: 10001;
}
#confirmBox .confirmBoxText{
    padding-bottom: 1.5rem;
}
#confirmBox .confirmBoxTextWrapper,
#confirmBox .confirmBoxTitle{
    padding: 1.5rem;
}
#confirmBox .confirmBoxTitle{
    background-color: #D4D4D2;
    color: #FFFFFF;
    font-weight: bold;
    letter-spacing: 1px;
}
#confirmBox .confirmButtons{
    text-align: center;
}
#confirmOverlay{
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.fancyHeader{
    display: -ms-grid;
    display: grid;
    text-align: center;
    grid-gap: 20px;
    -ms-grid-columns: 1fr 20px auto 20px 1fr;
    grid-template-columns: 1fr auto 1fr;
}
@supports ((display: -ms-grid) or (display: grid)){
    .fancyHeader:before,
    .fancyHeader:after{
        border-top: 1px solid #999999;
        content: "";

        -webkit-align-self: center;

            -ms-flex-item-align: center;

                    -ms-grid-row-align: center;

                align-self: center;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
figure{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    overflow: hidden;
}
figure img,
figure object{
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    max-height: 100%;
}
figure.objectFitImage{
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
}
.ratioWrapper figure.objectFitImage{
    height: auto;
    position: absolute;
}
figure.objectFitImage img{
    height: 100vh;
    position: absolute;
    width: 100vw;
    -o-object-fit: cover;
       object-fit: cover;
}
.contentCaptionedfigureLeft{
    display: block;
    margin: 2rem auto 2rem auto;
    width: 60%;
    min-width: 300px;
}
.contentCaptionedfigureRight{
    display: block;
    margin: 2rem auto 2rem auto;
    width: 60%;
    min-width: 300px;
}
.contentCaptionedfigureCentered{
    display: block;
    margin: 2rem auto 2rem auto;
    width: 60%;
    min-width: 300px;
}
.contentCaptionedfigureLeft figcaption,
.contentCaptionedfigureRight figcaption,
.contentCaptionedfigureCentered figcaption{
    font-style: italic;
    font-size: 90%;
    padding: 0;
}
.contentCaptionedfigureLeft figcaption p,
.contentCaptionedfigureRight figcaption p,
.contentCaptionedfigureCentered figcaption p{
    padding: 0;
    margin: 0;
}
.contentCaptionedfigureLeft img + figcaption,
.contentCaptionedfigureRight img + figcaption,
.contentCaptionedfigureCentered img + figcaption{
    margin-top: 1rem;
}
img {
  opacity: 1;
  -webkit-transition: opacity 0.9s;
  -o-transition: opacity 0.9s;
  transition: opacity 0.9s;
}
img[data-src] {
  opacity: 0;
}
/*img[src$=".svg"] {
    width: 100%;
}*/
@media only screen and (min-width: 800px){
    .contentCaptionedfigureLeft{
        display: inline-block;
        float: left;
        margin: 3rem 3rem 3rem 0;
        width: 50%;
        min-width: 300px;
    }
    .contentCaptionedfigureRight{
        display: inline-block;
        float: right;
        margin: 3rem 0 3rem 3rem;
        width: 50%;
        min-width: 300px;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.footerMenu{
    list-style: none;
    margin: 0;
    padding: 0;
}
.footerMenu a{
    display: block;
    text-decoration: none;
    white-space: nowrap;
}
.footerMenu li{
    -webkit-column-break-inside: avoid;
            break-inside: avoid-column;
    page-break-inside: avoid;
    position: relative;
    width: 100%;
}
.footerMenu::after{
    clear: both;
}
.footerMenu::before,
.footerMenu::after{
    content: "";
    display: table;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
input{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
select{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
textarea{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
optgroup{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
option{
    color: #333331;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
input[disabled],
select[disabled],
textarea[disabled]{
    cursor: not-allowed;
}
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
button{
    border-radius: 0;
}
input[type="radio"],
input[type="checkbox"]{
    display: inline-block;
    margin: 0 0.3rem 0 0;
}
input[type="text"],
input[type="date"],
input[type="range"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="month"],
textarea,
.styledSelect{
    border: 1px solid #666666;
    border-radius: 0;
    display: inline-block;
    padding: 0.5rem 1rem 0.5rem 1rem;
    width: 100%;
}
select{
    border: 1px solid #666666;
    border-radius: 0;
    margin-bottom: 0.5rem;
}
.styledSelect{
    background-color: #FFFFFF;
    padding: 0;
    position: relative;
}
.styledSelect select{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    min-width: 100%;
    padding: 0.5rem 24px 0.5rem 1rem;
}
.styledSelect select option{
    background-color: #FEFEFE;
}
.styledSelect::after{
    background-color: #666666;
    color: #FFFFFF;
    content: "\f078";
    display: block;
    font-family: "FontAwesome", sans-serif;
    height: 100%;
    padding-top: 5px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 24px;
    z-index: 0;
}
.checkRadio label{
    margin-left: 2rem;
    cursor: pointer;
}
.checkRadio label:first-of-type{
    margin-left: 0;
}
.formRowFull,
.formRowSplit{
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
}
.formRowSplit{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
}
.formRowFull > *{
    margin-bottom: 0.5rem;
    width: 100%;
}
.formRowSplit > *{
    margin-bottom: 0.5rem;
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
}
.validationError{
    color: #E64F0C;
}
div.error{
    color: #E64F0C;
}
.validationError::before{
    background-color: #E64F0C;
    border-radius: 50%;
    color: #FFFFFF;
    content: "!";
    display: inline-block;
    height: 1.8rem;
    line-height: 1.8rem;
    margin-right: 0.5rem;
    text-align: center;
    width: 1.8rem;
}
div.error::before{
    background-color: #E64F0C;
    border-radius: 50%;
    color: #FFFFFF;
    content: "!";
    display: inline-block;
    height: 1.8rem;
    line-height: 1.8rem;
    margin-right: 0.5rem;
    text-align: center;
    width: 1.8rem;
}
.required::before{
    content: "*\a0";
}
@media only screen and (min-width: 650px){
    .formRowFull,
    .formRowSplit{
        margin-bottom: 1.3rem;
    }
    .formRowSplit{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
            -ms-flex-direction: row;
                flex-direction: row;
    }
    .formRowSplit > *:first-child{
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        max-width: 200px;
        min-width: 150px;
        padding: 0 1.5rem 0 0;
        -webkit-align-self: center;
            -ms-flex-item-align: center;
                    -ms-grid-row-align: center;
                align-self: center;
        text-align: right;
    }
    .formRowFull > *:last-child,
    .formRowSplit > *{
        margin-bottom: 0;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.gradient1{
    background: rgb(0,125,168);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,125,168,1)), to(rgba(0,151,181,1)));
    background: -webkit-linear-gradient(bottom, rgba(0,125,168,1) 0%, rgba(0,151,181,1) 100%);
    background: -o-linear-gradient(bottom, rgba(0,125,168,1) 0%, rgba(0,151,181,1) 100%);
    background: linear-gradient(0deg, rgba(0,125,168,1) 0%, rgba(0,151,181,1) 100%);
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.imageCard{
    border: 1px solid #535351;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
    margin: 0 0 1.5rem 0;
    position: relative;
    width: 100%;
}
.imageCard .imageCardTextOverlay{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    bottom: 0;
    color: #FFFFFF;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    left: 0;
    overflow: hidden;
    padding: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.imageCard > *{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.imageCard h2,
.imageCard h3{
    color: #FFFFFF;
}
.imageCardFigure{
    position: relative;
}
.imageCardFigure figure{
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.imageCardFigure figure img,
.imageCardFigure figure object{
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100vw;
}
.imageCardFigure::after{
    clear: both;
    content: "";
    display: table;
}
.imageCardFigure::before{
    content: "";
    display: block;
    float: left;
    min-width: 100%;
    padding-top: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
}
.imageCardsWrapper{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-width: 100%;
    position: relative;
    width: 100%;
}
.imageCardsWrapper .imageCard.fillerCard.fillerCard{
    margin-bottom: 0;
    padding: 0;
    visibility: hidden;
}
.imageCardsWrapper .imageCard.fillerCard.fillerCard *{
    display: none;
}
.imageCardsWrapper[min-width~="1000px"] .imageCard{
    margin: 0 0 1.5rem 0;
    max-width: calc(20% - (1.5rem - (1.5rem / 5)));
    width: calc(20% - (1.5rem - (1.5rem / 5)));
}
.imageCardsWrapper[min-width~="400px"] .imageCard{
    margin: 0 0 1.5rem 0;
    max-width: calc(50% - (1.5rem - (1.5rem / 2)));
    width: calc(50% - (1.5rem - (1.5rem / 2)));
}
.imageCardsWrapper[min-width~="600px"] .imageCard{
    margin: 0 0 1.5rem 0;
    max-width: calc(33.333% - (1.5rem - (1.5rem / 3)));
    width: calc(33.333% - (1.5rem - (1.5rem / 3)));
}
.imageCardsWrapper[min-width~="800px"] .imageCard{
    margin: 0 0 1.5rem 0;
    max-width: calc(25% - (1.5rem - (1.5rem / 4)));
    width: calc(25% - (1.5rem - (1.5rem / 4)));
}
@media only screen and (min-width: 400px){
    .imageCardsWrapper{
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.listings{
    margin: 3.0rem 0 3.0rem 0;
}
.listings > *{
    border-bottom: 1px solid #B3B3B1;
    margin: 0 0 1.5rem 0;
    padding: 0 0 1.5rem 0;
}
.listings > *:last-child{
    border-bottom: none;
}
.listings > a{
    display: block;
}
.listingTeaser{
    margin-bottom: 1.5rem;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
.mediaObject{
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    position: relative;
    width: 100%;
}
.mediaObject > *{
    padding-bottom: 3rem;
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.mediaObject .mediaObjectPrimary{
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3;
}
.mediaObject .mediaObjectAsideLeft{
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
}
.mediaObject .mediaObjectAsideRight{
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
}
@media only screen and (min-width: 500px){
    .mediaObject{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row;
            -ms-flex-flow: row;
                flex-flow: row;
    }
    .mediaObject .mediaObjectPrimary{
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
            -ms-flex-order: 2;
                order: 2;
        padding: 0;
    }
    .mediaObject .mediaObjectPrimary ~ .mediaObjectAsideLeft{
        padding-bottom: 0;
        /*padding-right: var(--media-object-spacing);*/
    }
    .mediaObject .mediaObjectPrimary ~ .mediaObjectAsideRight{
        padding-bottom: 0;
        /*padding-left: var(--media-object-spacing);*/
    }
    .mediaObject .mediaObjectAsideLeft{
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
    }
    .mediaObject .mediaObjectAsideLeft{
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 43rem;
        min-width: 20rem;
    }
    .mediaObject .mediaObjectAsideRight{
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 43rem;
        min-width: 20rem;
    }
    .mediaObject .mediaObjectAsideRight{
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
            -ms-flex-order: 3;
                order: 3;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
.confirmBoxButton{
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #737371;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    cursor: pointer;
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: normal;
    margin: 0.5rem;
    padding: 1.5rem 3.0rem 1.5rem 3.0rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.4rem;
}
.confirmBoxButton:hover{
    background-color: #535351;
}
.confirmBoxButton:focus{
    background-color: #535351;
}
.confirmBoxButton *{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
#confirmBox{
    background-color: #FFFFFF;
    border-radius: 5px;
    left: 50%;
    line-height: 1.4;
    max-width: 300px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
    z-index: 10001;
    border: 1px solid #3D3D3B;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
#confirmBox .confirmBoxText{
    padding-bottom: 1.5rem;
}
#confirmBox .confirmBoxTextWrapper,
#confirmBox .confirmBoxTitle{
    padding: 1.5rem;
}
#confirmBox .confirmBoxTitle{
    background-color: #3D3D3B;
    color: #FFFFFF;
    font-weight: bold;
    letter-spacing: 1px;
}
#confirmBox .confirmButtons{
    text-align: center;
}
#confirmOverlay{
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}
#confirmBox .confirmBoxTitle button{
    float: right;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
#closeModal img{
    width: 2rem;
    height: 2rem;
}

:root{
    /* pagination */
}
.pagination{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 100%;
    position: relative;
    width: 100%;
}
.pagination a{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #F4F4F2;
    color: #333333;
    border: 1px solid #CDCDC8;
    border-right: none;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    height: 2.6rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: 1;
    min-height: 2.6rem;
    min-width: 2.6rem;
    position: relative;
    text-decoration: none;
}
.pagination a.paginationCurrent{
    background-color: #A9A9A7;
    border-color: #A9A9A7;
    color: #ffffff;
}
.pagination a.paginationFirst span,
.pagination a.paginationLast span,
.pagination a.paginationPrev span,
.pagination a.paginationNext span{
    left: -9999px;
    position: absolute;
    top: -9999px;
}
.pagination a.paginationFirst span,
.pagination a.paginationPrev span{
    padding: 0 0 0 0.8rem;
}
.pagination a.paginationFirst{
    background-color: #ffffff;
}
.pagination a.paginationLast{
    background-color: #ffffff;
}
.pagination a.paginationPrev{
    background-color: #ffffff;
}
.pagination a.paginationNext{
    background-color: #ffffff;
}
.pagination a.paginationFirst::before{
    content: "\f100";
}
.pagination a.paginationFirst::before,
.pagination a.paginationLast::after,
.pagination a.paginationPrev::before,
.pagination a.paginationNext::after{
    display: inline-block;
    font-family: "FontAwesome", sans-serif;
    font-size: inherit;
    text-rendering: auto;
}
.pagination a.paginationLast span,
.pagination a.paginationNext span{
    padding: 0 0.5rem 0 0;
}
.pagination a.paginationLast::after{
    content: "\f101";
}
.pagination a.paginationNext::after{
    content: "\f105";
}
.pagination a.paginationPrev::before{
    content: "\f104";
}
.pagination a:first-child{
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.pagination a:last-child{
    border-right: 1px solid #CDCDC8;
}
.pagination a:last-child{
    border-bottom-right-radius: 4px;
    border-right: 1px solid #CDCDC8;
    border-top-right-radius: 4px;
}
.pagination a:not([class*="paginationCurrent"]):hover{
    background-color: #B8B8AF;
    border-color: #B8B8AF;
    color: #FFFFFF;
}
@media only screen and (min-width: 680px){
    .pagination a{
        height: 3.2rem;
        min-height: 3.2rem;
        min-width: 3.2rem;
    }
}

div.pp_default .pp_top,
div.pp_default .pp_top .pp_middle,
div.pp_default .pp_top .pp_left,
div.pp_default .pp_top .pp_right,
div.pp_default .pp_bottom,
div.pp_default .pp_bottom .pp_left,
div.pp_default .pp_bottom .pp_middle,
div.pp_default .pp_bottom .pp_right{
    height: 13px;
}
div.pp_default .pp_top .pp_left{
    background: url(/images/prettyPhoto/default/sprite.png) -78px -93px no-repeat;
}
div.pp_default .pp_top .pp_middle{
    background: url(/images/prettyPhoto/default/sprite_x.png) top left repeat-x;
}
div.pp_default .pp_top .pp_right{
    background: url(/images/prettyPhoto/default/sprite.png) -112px -93px no-repeat;
}
div.pp_default .pp_content .ppt{
    color: #f8f8f8;
}
div.pp_default .pp_content_container .pp_left{
    background: url(/images/prettyPhoto/default/sprite_y.png) -7px 0 repeat-y;
    padding-left: 13px;
}
div.pp_default .pp_content_container .pp_right{
    background: url(/images/prettyPhoto/default/sprite_y.png) top right repeat-y;
    padding-right: 13px;
}
div.pp_default .pp_next:hover{
    background: url(/images/prettyPhoto/default/sprite_next.png) center right no-repeat;
    cursor: pointer;
}
div.pp_default .pp_previous:hover{
    background: url(/images/prettyPhoto/default/sprite_prev.png) center left no-repeat;
    cursor: pointer;
}
div.pp_default .pp_expand{
    background: url(/images/prettyPhoto/default/sprite.png) 0 -29px no-repeat;
    cursor: pointer;
    height: 28px;
    width: 28px;
}
div.pp_default .pp_expand:hover{
    background: url(/images/prettyPhoto/default/sprite.png) 0 -56px no-repeat;
    cursor: pointer;
}
div.pp_default .pp_contract{
    background: url(/images/prettyPhoto/default/sprite.png) 0 -84px no-repeat;
    cursor: pointer;
    height: 28px;
    width: 28px;
}
div.pp_default .pp_contract:hover{
    background: url(/images/prettyPhoto/default/sprite.png) 0 -113px no-repeat;
    cursor: pointer;
}
div.pp_default .pp_close{
    background: url(/images/prettyPhoto/default/sprite.png) 2px 1px no-repeat;
    cursor: pointer;
    height: 30px;
    width: 30px;
}
div.pp_default .pp_gallery ul li a{
    background: url(/images/prettyPhoto/default/default_thumb.png) center center #f8f8f8;
    border: 1px solid #aaaaaa;
}
div.pp_default .pp_social{
    margin-top: 7px;
}
div.pp_default .pp_gallery a.pp_arrow_previous,
div.pp_default .pp_gallery a.pp_arrow_next{
    left: auto;
    position: static;
}
div.pp_default .pp_nav .pp_play,
div.pp_default .pp_nav .pp_pause{
    background: url(/images/prettyPhoto/default/sprite.png) -51px 1px no-repeat;
    height: 30px;
    width: 30px;
}
div.pp_default .pp_nav .pp_pause{
    background-position: -51px -29px;
}
div.pp_default a.pp_arrow_previous,
div.pp_default a.pp_arrow_next{
    background: url(/images/prettyPhoto/default/sprite.png) -31px -3px no-repeat;
    height: 20px;
    margin: 4px 0 0;
    width: 20px;
}
div.pp_default a.pp_arrow_next{
    background-position: -82px -3px;
    left: 52px;
}
div.pp_default .pp_content_container .pp_details{
    margin-top: 5px;
}
div.pp_default .pp_nav{
    clear: none;
    height: 30px;
    position: relative;
    width: 110px;
}
div.pp_default .pp_nav .currentTextHolder{
    color: #999999;
    font-family: Georgia;
    font-size: 11px;
    font-style: italic;
    left: 75px;
    line-height: 25px;
    margin: 0;
    padding: 0 0 0 10px;
    position: absolute;
    top: 2px;
}
div.pp_default .pp_close:hover,
div.pp_default .pp_nav .pp_play:hover,
div.pp_default .pp_nav .pp_pause:hover,
div.pp_default .pp_arrow_next:hover,
div.pp_default .pp_arrow_previous:hover{
    opacity: 0.7;
}
div.pp_default .pp_description{
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    margin: 5px 50px 5px 0;
}
div.pp_default .pp_bottom .pp_left{
    background: url(/images/prettyPhoto/default/sprite.png) -78px -127px no-repeat;
}
div.pp_default .pp_bottom .pp_middle{
    background: url(/images/prettyPhoto/default/sprite_x.png) bottom left repeat-x;
}
div.pp_default .pp_bottom .pp_right{
    background: url(/images/prettyPhoto/default/sprite.png) -112px -127px no-repeat;
}
div.pp_default .pp_loaderIcon{
    background: url(/images/prettyPhoto/default/loader.gif) center center no-repeat;
}
div.light_rounded .pp_top .pp_left{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -88px -53px no-repeat;
}
div.light_rounded .pp_top .pp_right{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -110px -53px no-repeat;
}
div.light_rounded .pp_next:hover{
    background: url(/images/prettyPhoto/light_rounded/btnNext.png) center right no-repeat;
    cursor: pointer;
}
div.light_rounded .pp_previous:hover{
    background: url(/images/prettyPhoto/light_rounded/btnPrevious.png) center left no-repeat;
    cursor: pointer;
}
div.light_rounded .pp_expand{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -31px -26px no-repeat;
    cursor: pointer;
}
div.light_rounded .pp_expand:hover{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -31px -47px no-repeat;
    cursor: pointer;
}
div.light_rounded .pp_contract{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) 0 -26px no-repeat;
    cursor: pointer;
}
div.light_rounded .pp_contract:hover{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) 0 -47px no-repeat;
    cursor: pointer;
}
div.light_rounded .pp_close{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -1px -1px no-repeat;
    cursor: pointer;
    height: 22px;
    width: 75px;
}
div.light_rounded .pp_nav .pp_play{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -1px -100px no-repeat;
    height: 15px;
    width: 14px;
}
div.light_rounded .pp_nav .pp_pause{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -24px -100px no-repeat;
    height: 15px;
    width: 14px;
}
div.light_rounded .pp_arrow_previous{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) 0 -71px no-repeat;
}
div.light_rounded .pp_arrow_next{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -22px -71px no-repeat;
}
div.light_rounded .pp_bottom .pp_left{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -88px -80px no-repeat;
}
div.light_rounded .pp_bottom .pp_right{
    background: url(/images/prettyPhoto/light_rounded/sprite.png) -110px -80px no-repeat;
}
div.dark_rounded .pp_top .pp_left{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -88px -53px no-repeat;
}
div.dark_rounded .pp_top .pp_right{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -110px -53px no-repeat;
}
div.dark_rounded .pp_content_container .pp_left{
    background: url(/images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat-y;
}
div.dark_rounded .pp_content_container .pp_right{
    background: url(/images/prettyPhoto/dark_rounded/contentPattern.png) top right repeat-y;
}
div.dark_rounded .pp_next:hover{
    background: url(/images/prettyPhoto/dark_rounded/btnNext.png) center right no-repeat;
    cursor: pointer;
}
div.dark_rounded .pp_previous:hover{
    background: url(/images/prettyPhoto/dark_rounded/btnPrevious.png) center left no-repeat;
    cursor: pointer;
}
div.dark_rounded .pp_expand{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -31px -26px no-repeat;
    cursor: pointer;
}
div.dark_rounded .pp_expand:hover{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -31px -47px no-repeat;
    cursor: pointer;
}
div.dark_rounded .pp_contract{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) 0 -26px no-repeat;
    cursor: pointer;
}
div.dark_rounded .pp_contract:hover{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) 0 -47px no-repeat;
    cursor: pointer;
}
div.dark_rounded .pp_close{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -1px -1px no-repeat;
    cursor: pointer;
    height: 22px;
    width: 75px;
}
div.dark_rounded .pp_description{
    color: #ffffff;
    margin-right: 85px;
}
div.dark_rounded .pp_nav .pp_play{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -1px -100px no-repeat;
    height: 15px;
    width: 14px;
}
div.dark_rounded .pp_nav .pp_pause{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -24px -100px no-repeat;
    height: 15px;
    width: 14px;
}
div.dark_rounded .pp_arrow_previous{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) 0 -71px no-repeat;
}
div.dark_rounded .pp_arrow_next{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -22px -71px no-repeat;
}
div.dark_rounded .pp_bottom .pp_left{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -88px -80px no-repeat;
}
div.dark_rounded .pp_bottom .pp_right{
    background: url(/images/prettyPhoto/dark_rounded/sprite.png) -110px -80px no-repeat;
}
div.dark_rounded .pp_loaderIcon{
    background: url(/images/prettyPhoto/dark_rounded/loader.gif) center center no-repeat;
}
div.dark_square .pp_left,
div.dark_square .pp_middle,
div.dark_square .pp_right,
div.dark_square .pp_content{
    background: #000000;
}
div.dark_square .pp_description{
    color: #ffffff;
    margin: 0 85px 0 0;
}
div.dark_square .pp_loaderIcon{
    background: url(/images/prettyPhoto/dark_square/loader.gif) center center no-repeat;
}
div.dark_square .pp_expand{
    background: url(/images/prettyPhoto/dark_square/sprite.png) -31px -26px no-repeat;
    cursor: pointer;
}
div.dark_square .pp_expand:hover{
    background: url(/images/prettyPhoto/dark_square/sprite.png) -31px -47px no-repeat;
    cursor: pointer;
}
div.dark_square .pp_contract{
    background: url(/images/prettyPhoto/dark_square/sprite.png) 0 -26px no-repeat;
    cursor: pointer;
}
div.dark_square .pp_contract:hover{
    background: url(/images/prettyPhoto/dark_square/sprite.png) 0 -47px no-repeat;
    cursor: pointer;
}
div.dark_square .pp_close{
    background: url(/images/prettyPhoto/dark_square/sprite.png) -1px -1px no-repeat;
    cursor: pointer;
    height: 22px;
    width: 75px;
}
div.dark_square .pp_nav{
    clear: none;
}
div.dark_square .pp_nav .pp_play{
    background: url(/images/prettyPhoto/dark_square/sprite.png) -1px -100px no-repeat;
    height: 15px;
    width: 14px;
}
div.dark_square .pp_nav .pp_pause{
    background: url(/images/prettyPhoto/dark_square/sprite.png) -24px -100px no-repeat;
    height: 15px;
    width: 14px;
}
div.dark_square .pp_arrow_previous{
    background: url(/images/prettyPhoto/dark_square/sprite.png) 0 -71px no-repeat;
}
div.dark_square .pp_arrow_next{
    background: url(/images/prettyPhoto/dark_square/sprite.png) -22px -71px no-repeat;
}
div.dark_square .pp_next:hover{
    background: url(/images/prettyPhoto/dark_square/btnNext.png) center right no-repeat;
    cursor: pointer;
}
div.dark_square .pp_previous:hover{
    background: url(/images/prettyPhoto/dark_square/btnPrevious.png) center left no-repeat;
    cursor: pointer;
}
div.light_square .pp_expand{
    background: url(/images/prettyPhoto/light_square/sprite.png) -31px -26px no-repeat;
    cursor: pointer;
}
div.light_square .pp_expand:hover{
    background: url(/images/prettyPhoto/light_square/sprite.png) -31px -47px no-repeat;
    cursor: pointer;
}
div.light_square .pp_contract{
    background: url(/images/prettyPhoto/light_square/sprite.png) 0 -26px no-repeat;
    cursor: pointer;
}
div.light_square .pp_contract:hover{
    background: url(/images/prettyPhoto/light_square/sprite.png) 0 -47px no-repeat;
    cursor: pointer;
}
div.light_square .pp_close{
    background: url(/images/prettyPhoto/light_square/sprite.png) -1px -1px no-repeat;
    cursor: pointer;
    height: 22px;
    width: 75px;
}
div.light_square .pp_nav .pp_play{
    background: url(/images/prettyPhoto/light_square/sprite.png) -1px -100px no-repeat;
    height: 15px;
    width: 14px;
}
div.light_square .pp_nav .pp_pause{
    background: url(/images/prettyPhoto/light_square/sprite.png) -24px -100px no-repeat;
    height: 15px;
    width: 14px;
}
div.light_square .pp_arrow_previous{
    background: url(/images/prettyPhoto/light_square/sprite.png) 0 -71px no-repeat;
}
div.light_square .pp_arrow_next{
    background: url(/images/prettyPhoto/light_square/sprite.png) -22px -71px no-repeat;
}
div.light_square .pp_next:hover{
    background: url(/images/prettyPhoto/light_square/btnNext.png) center right no-repeat;
    cursor: pointer;
}
div.light_square .pp_previous:hover{
    background: url(/images/prettyPhoto/light_square/btnPrevious.png) center left no-repeat;
    cursor: pointer;
}
div.facebook .pp_top .pp_left{
    background: url(/images/prettyPhoto/facebook/sprite.png) -88px -53px no-repeat;
}
div.facebook .pp_top .pp_middle{
    background: url(/images/prettyPhoto/facebook/contentPatternTop.png) top left repeat-x;
}
div.facebook .pp_top .pp_right{
    background: url(/images/prettyPhoto/facebook/sprite.png) -110px -53px no-repeat;
}
div.facebook .pp_content_container .pp_left{
    background: url(/images/prettyPhoto/facebook/contentPatternLeft.png) top left repeat-y;
}
div.facebook .pp_content_container .pp_right{
    background: url(/images/prettyPhoto/facebook/contentPatternRight.png) top right repeat-y;
}
div.facebook .pp_expand{
    background: url(/images/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
    cursor: pointer;
}
div.facebook .pp_expand:hover{
    background: url(/images/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
    cursor: pointer;
}
div.facebook .pp_contract{
    background: url(/images/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
    cursor: pointer;
}
div.facebook .pp_contract:hover{
    background: url(/images/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
    cursor: pointer;
}
div.facebook .pp_close{
    background: url(/images/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
    cursor: pointer;
    height: 22px;
    width: 22px;
}
div.facebook .pp_description{
    margin: 0 37px 0 0;
}
div.facebook .pp_loaderIcon{
    background: url(/images/prettyPhoto/facebook/loader.gif) center center no-repeat;
}
div.facebook .pp_arrow_previous{
    background: url(/images/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
    height: 22px;
    margin-top: 0;
    width: 22px;
}
div.facebook .pp_arrow_previous.disabled{
    background-position: 0 -96px;
    cursor: default;
}
div.facebook .pp_arrow_next{
    background: url(/images/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
    height: 22px;
    margin-top: 0;
    width: 22px;
}
div.facebook .pp_arrow_next.disabled{
    background-position: -32px -96px;
    cursor: default;
}
div.facebook .pp_nav{
    margin-top: 0;
}
div.facebook .pp_nav p{
    font-size: 15px;
    padding: 0 3px 0 4px;
}
div.facebook .pp_nav .pp_play{
    background: url(/images/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
    height: 22px;
    width: 22px;
}
div.facebook .pp_nav .pp_pause{
    background: url(/images/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
    height: 22px;
    width: 22px;
}
div.facebook .pp_next:hover{
    background: url(/images/prettyPhoto/facebook/btnNext.png) center right no-repeat;
    cursor: pointer;
}
div.facebook .pp_previous:hover{
    background: url(/images/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;
    cursor: pointer;
}
div.facebook .pp_bottom .pp_left{
    background: url(/images/prettyPhoto/facebook/sprite.png) -88px -80px no-repeat;
}
div.facebook .pp_bottom .pp_middle{
    background: url(/images/prettyPhoto/facebook/contentPatternBottom.png) top left repeat-x;
}
div.facebook .pp_bottom .pp_right{
    background: url(/images/prettyPhoto/facebook/sprite.png) -110px -80px no-repeat;
}
div.pp_pic_holder a:focus{
    outline: none;
}
div.pp_overlay{
    background: #000000;
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9500;
}
div.pp_pic_holder{
    display: none;
    position: absolute;
    width: 100px;
    z-index: 10000;
}
.pp_content{
    height: 40px;
    min-width: 40px;
}
* html .pp_content{
    width: 40px;
}
.pp_content_container{
    position: relative;
    text-align: left;
    width: 100%;
}
.pp_content_container .pp_left{
    padding-left: 20px;
}
.pp_content_container .pp_right{
    padding-right: 20px;
}
.pp_content_container .pp_details{
    float: left;
    margin: 10px 0 2px;
}
.pp_description{
    display: none;
    margin: 0;
}
.pp_social{
    float: left;
    margin: 0;
}
.pp_social .facebook{
    float: left;
    margin-left: 5px;
    overflow: hidden;
    width: 55px;
}
.pp_social .twitter{
    float: left;
}
.pp_nav{
    clear: right;
    float: left;
    margin: 3px 10px 0 0;
}
.pp_nav p{
    float: left;
    margin: 2px 4px;
    white-space: nowrap;
}
.pp_nav .pp_play,
.pp_nav .pp_pause{
    float: left;
    margin-right: 4px;
    text-indent: -10000px;
}
a.pp_arrow_previous,
a.pp_arrow_next{
    display: block;
    float: left;
    height: 15px;
    margin-top: 3px;
    overflow: hidden;
    text-indent: -10000px;
    width: 14px;
}
.pp_hoverContainer{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2000;
}
.pp_gallery{
    display: none;
    left: 50%;
    margin-top: -50px;
    position: absolute;
    z-index: 10000;
}
.pp_gallery div{
    float: left;
    overflow: hidden;
    position: relative;
}
.pp_gallery ul{
    float: left;
    height: 35px;
    margin: 0 0 0 5px;
    padding: 0;
    position: relative;
    white-space: nowrap;
}
.pp_gallery ul a{
    border: 1px rgba(0,0,0,0.5) solid;
    display: block;
    float: left;
    height: 33px;
    overflow: hidden;
}
.pp_gallery ul a img{
    border: 0;
}
.pp_gallery li{
    display: block;
    float: left;
    margin: 0 5px 0 0;
    padding: 0;
}
.pp_gallery li.default a{
    background: url(/images/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
    display: block;
    height: 33px;
    width: 50px;
}
.pp_gallery .pp_arrow_previous,
.pp_gallery .pp_arrow_next{
    margin-top: 7px!important;
}
a.pp_next{
    background: url(/images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
    display: block;
    float: right;
    height: 100%;
    text-indent: -10000px;
    width: 49%;
}
a.pp_previous{
    background: url(/images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
    display: block;
    float: left;
    height: 100%;
    text-indent: -10000px;
    width: 49%;
}
a.pp_expand,
a.pp_contract{
    cursor: pointer;
    display: none;
    height: 20px;
    position: absolute;
    right: 30px;
    text-indent: -10000px;
    top: 10px;
    width: 20px;
    z-index: 20000;
}
a.pp_close{
    display: block;
    line-height: 22px;
    position: absolute;
    right: 0;
    text-indent: -10000px;
    top: 0;
}
.pp_loaderIcon{
    display: block;
    height: 24px;
    left: 50%;
    margin: -12px 0 0 -12px;
    position: absolute;
    top: 50%;
    width: 24px;
}
#pp_full_res{
    line-height: 1!important;
}
#pp_full_res .pp_inline{
    text-align: left;
}
#pp_full_res .pp_inline p{
    margin: 0 0 15px;
}
div.ppt{
    color: #ffffff;
    display: none;
    font-size: 17px;
    margin: 0 0 5px 15px;
    z-index: 9999;
}
div.pp_default .pp_content,
div.light_rounded .pp_content{
    background-color: #ffffff;
}
div.pp_default #pp_full_res .pp_inline,
div.light_rounded .pp_content .ppt,
div.light_rounded #pp_full_res .pp_inline,
div.light_square .pp_content .ppt,
div.light_square #pp_full_res .pp_inline,
div.facebook .pp_content .ppt,
div.facebook #pp_full_res .pp_inline{
    color: #000000;
}
div.pp_default .pp_gallery ul li a:hover,
div.pp_default .pp_gallery ul li.selected a,
.pp_gallery ul a:hover,
.pp_gallery li.selected a{
    border-color: #ffffff;
}
div.pp_default .pp_details,
div.light_rounded .pp_details,
div.dark_rounded .pp_details,
div.dark_square .pp_details,
div.light_square .pp_details,
div.facebook .pp_details{
    position: relative;
}
div.light_rounded .pp_top .pp_middle,
div.light_rounded .pp_content_container .pp_left,
div.light_rounded .pp_content_container .pp_right,
div.light_rounded .pp_bottom .pp_middle,
div.light_square .pp_left,
div.light_square .pp_middle,
div.light_square .pp_right,
div.light_square .pp_content,
div.facebook .pp_content{
    background: #ffffff;
}
div.light_rounded .pp_description,
div.light_square .pp_description{
    margin-right: 85px;
}
div.light_rounded .pp_gallery a.pp_arrow_previous,
div.light_rounded .pp_gallery a.pp_arrow_next,
div.dark_rounded .pp_gallery a.pp_arrow_previous,
div.dark_rounded .pp_gallery a.pp_arrow_next,
div.dark_square .pp_gallery a.pp_arrow_previous,
div.dark_square .pp_gallery a.pp_arrow_next,
div.light_square .pp_gallery a.pp_arrow_previous,
div.light_square .pp_gallery a.pp_arrow_next{
    margin-top: 12px!important;
}
div.light_rounded .pp_arrow_previous.disabled,
div.dark_rounded .pp_arrow_previous.disabled,
div.dark_square .pp_arrow_previous.disabled,
div.light_square .pp_arrow_previous.disabled{
    background-position: 0 -87px;
    cursor: default;
}
div.light_rounded .pp_arrow_next.disabled,
div.dark_rounded .pp_arrow_next.disabled,
div.dark_square .pp_arrow_next.disabled,
div.light_square .pp_arrow_next.disabled{
    background-position: -22px -87px;
    cursor: default;
}
div.light_rounded .pp_loaderIcon,
div.light_square .pp_loaderIcon{
    background: url(/images/prettyPhoto/light_rounded/loader.gif) center center no-repeat;
}
div.dark_rounded .pp_top .pp_middle,
div.dark_rounded .pp_content,
div.dark_rounded .pp_bottom .pp_middle{
    background: url(/images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat;
}
div.dark_rounded .currentTextHolder,
div.dark_square .currentTextHolder{
    color: #c4c4c4;
}
div.dark_rounded #pp_full_res .pp_inline,
div.dark_square #pp_full_res .pp_inline{
    color: #ffffff;
}
.pp_top,
.pp_bottom{
    height: 20px;
    position: relative;
}
* html .pp_top,
* html .pp_bottom{
    padding: 0 20px;
}
.pp_top .pp_left,
.pp_bottom .pp_left{
    height: 20px;
    left: 0;
    position: absolute;
    width: 20px;
}
.pp_top .pp_middle,
.pp_bottom .pp_middle{
    height: 20px;
    left: 20px;
    position: absolute;
    right: 20px;
}
* html .pp_top .pp_middle,
* html .pp_bottom .pp_middle{
    left: 0;
    position: static;
}
.pp_top .pp_right,
.pp_bottom .pp_right{
    height: 20px;
    left: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
}
.pp_fade,
.pp_gallery li.default a img{
    display: none;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
.secondaryNavMenu,
.secondaryNavMenu ul{
    font-size: 1.6rem;
    list-style: none;
    margin: 0;
    padding: 0;
}
.secondaryNavMenu::after{
    clear: both;
}
.secondaryNavMenu::before,
.secondaryNavMenu::after{
    content: "";
    display: table;
}
.secondaryNavMenu li{
    width: 100%;
}
.secondaryNavMenu li.here > a:first-child,
.secondaryNavMenu li.parent-here > a:first-child{
    font-weight: bold;
}
.secondaryNavMenu a{
    color: #4b5565;
    display: block;
    line-height: normal;
    padding: 1rem 2rem 1rem 2rem;
    text-decoration: none;
    width: 100%;
}
.secondaryNavMenu a:hover{
    color: #4b5565;
}
.secondaryNavMenu ul a{
    padding: 1rem 2rem 1rem 3rem;
}
.secondaryNavMenu ul ul a{
    padding: 1rem 2rem 1rem 4rem;
}





:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
.simpleSearchForm{
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    border: none;
}
.simpleSearchForm .shownFields{
    background: #FFFFFF;
    width: 100%;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    position: relative;
    overflow: hidden;
}
.simpleSearchForm .shownFields > *{
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.simpleSearchForm button{
    background: #F4F4F2;
    border: 1px solid #B3B3B1;
    border-left: none;
    color: #5D5D5B;
    cursor: pointer;
    font: inherit;
    font-size: 80%;
    line-height: 1;
    margin: 0;
    text-align: center;
    text-indent: -9999px;
    width: 20%;
    min-width: 3rem;
    max-width: 3.5rem;
    position: relative;
}
.simpleSearchForm button:hover{
    background: #C9C9C7;
    color: #FFFFFF;
}
.simpleSearchForm button:hover::after{
    border-color: rgba(0, 0, 0, 0) #ffffff;
}
.simpleSearchForm button::after{
    border-color: rgba(0, 0, 0, 0) #B3B3B1;
    border-style: solid;
    border-width: 5px 0 5px 10px;
    content: "";
    display: block;
    height: 0;
    left: calc(50% - 5px);
    position: absolute;
    top: calc(50% - 5px);
    width: 0;
}
.simpleSearchForm input{
    background: transparent;
    border: 1px solid #B3B3B1;
    color: #5D5D5B;
    font-family: "Source Sans Pro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 1;
    margin: 0;
    padding: 0.85rem 1rem 0.85rem 1rem;
    width: 100%;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
.slide{
    display: none;
    position: relative;
    vertical-align: middle;
    width: 100%;
}
.slide img{
    height: auto;
    max-height: none;
    max-width: none;
    min-width: 100%;
    position: relative;
    width: 100%;
}
.slide:first-child{
    display: block;
}
.slideCopy{
    padding-bottom: 1.5rem;
}
.slideHeadline{
    font-size: 2.4rem;
    margin: 0 0 1.5rem 0;
}
.slides{
    position: relative;
    width: 100%;
}
.slides a{
    text-decoration: none;
}
.slidesDots{
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    width: 100%;
    z-index: 1000;
}
.slidesDots span{
    background: #dcdcdc;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 15px;
    margin: 12px 8px 15px 8px;
    text-indent: -9999px;
    width: 15px;
}
.slidesDots span.cycle-pager-active{
    background: #bc1e21;
}
.slidesNav{
    display: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: absolute;
    top: calc(50% - 24px);
}
.slidesNav > div{
    background-color: #FFFFFF;
    cursor: pointer;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.slidesNav > div > *{
    -webkit-box-flex:0;
    -webkit-flex:0 0 auto;
        -ms-flex:0 0 auto;
            flex:0 0 auto;
}
.slidesNav > div:hover{
    color: #484846;
}
.slidesNav > div:first-child{
    padding-right: 3px;
}
.slidesNav > div:last-child{
    padding-left: 3px;
}
.slidesWrapper{
    position: relative;
}
@media only screen and (min-width: 800px){
    .slidesNav{
        -js-display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        left: -24px;
        right: -24px;
    }
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
/*************************************/
/* TABS */
/******************************************************************************************************/
.tabContent > div{
    border-bottom: 1px solid #E9E9E6;
    margin: 0 30px 0 30px;
    padding: 15px 0 15px 0;
}
.tabs .tabContent{
    border: 1px solid #E9E9E1;
}
.tabs > ul{
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.tabs > ul li{
    display: inline-block;
    float: left;
    margin: 0;
    position: relative;
}
.tabs > ul li a{
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: 1.2rem;
    margin-bottom: -1px;
    outline: none;
    padding: 20px 40px 20px 40px;
    text-decoration: none;
    text-transform: uppercase;
}
.tabs > ul li.active a,
.tabs > ul li a:hover,
.tabs > ul li.active a:hover{
    background-color: #E9E9E1;
}

:root{

    /* fonts */

    /* common sizes */

    /* common colors */

    /* transparent colors */

    /* common grays */

    /* mobile menu colors */

    /* site colors */

}
:root{
}
/* UL */
.utilityNav,
.utilityNav ul{
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.utilityNav::after,
.utilityNav ul::after{
    clear: both;
}
.utilityNav::before,
.utilityNav::after,
.utilityNav ul::before,
.utilityNav ul::after{
    content: "";
    display: table;
}
/* LI */
.utilityNav li{
    cursor: pointer;
    display: inline-block;
    padding: 0 0.5rem 0 0.5rem;
    position: relative;
    width: auto;
}
.utilityNav li.separator{
    padding: 0;
}
.utilityNav li:hover ul{
    display: block;
}
/* A */
.utilityNav a{
    display: block;
    text-decoration: none;
    width: 100%;
}
.utilityNav a i::before{
    padding: 0 0.5rem 0 0.5rem;
}
/* SPAN */
.utilityNav span{
    display: block;
}
/* SUB UL */
.utilityNav ul{
    background-color: #ffffff;
    border: 1px solid #5D5D5B;
    border-radius: 2px;
    display: none;
    position: absolute;
    top: auto;
    z-index: 200;
    white-space: nowrap;
}
.utilityNav ul li{
    width: 100%;
}
